<template></template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  watch,
  onUnmounted,
  ref
} from 'vue';

import { shippingMapClusterNewPost } from '$modules/shipping/helpers/shipping-map-cluster';
import MaplibreGL from 'maplibre-gl/dist/maplibre-gl'

export default defineComponent({
  name: 'ShippingMapNewPost',
  props: {
    map: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      default: () => ([])
    },
    icon: {
      type: String,
      required: true
    },
    iconActive: {
      type: String,
      required: true
    },
    selected: {
      type: Object,
      default: null
    }
  },
  setup (props, { emit }) {
    const popup = ref(null)
    const mounted = ref(false)
    const selected = computed(() => props.selected)
    const data = computed(() => props.list)

    const handleUpdate = () => {
      const list = data.value.filter(i => i.ref !== props.selected?.ref);
      const geojsonData = shippingMapClusterNewPost(list)
      const selectedData = shippingMapClusterNewPost(props.selected ? [props.selected] : [])

      props.map.getSource('np').setData(geojsonData);
      props.map.getSource('np-selected').setData(selectedData);

      if (!props.selected) {
        popup.value?.remove();
        popup.value = null

        return
      }

      popup.value = new MaplibreGL.Popup({
        offset: {
          bottom: [0, -55]
        }
      })
        .setLngLat([props.selected?.long, props.selected?.lat])
        .setHTML(props.selected?.address)
        .setMaxWidth('500px')
        .addTo(props.map);
    }

    onMounted(() => {
      props.map.loadImage(props.icon)
        .then((image) => props.map.addImage('np-icon', image.data))

      props.map.loadImage(props.iconActive)
        .then((image) => props.map.addImage('np-icon-active', image.data))

      props.map.addSource('np', {
        type: 'geojson',
        data: shippingMapClusterNewPost([]),
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      props.map.addSource('np-selected', {
        type: 'geojson',
        data: shippingMapClusterNewPost([]),
        cluster: false,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      props.map.addLayer({
        id: 'np',
        type: 'circle',
        source: 'np',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 10, '#f1f075', 30, '#f28cb1'],
          'circle-radius': ['step', ['get', 'point_count'], 15, 10, 20, 30, 25]
        }
      });

      props.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'np',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Noto Sans Regular'],
          'text-size': 12
        }
      });

      props.map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'np',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'np-icon',
          'icon-size': 0.3,
          'icon-anchor': 'bottom'
        }
      });

      props.map.addLayer({
        'id': 'np-selected',
        'type': 'symbol',
        'source': 'np-selected',
        'layout': {
          'icon-image': 'np-icon-active',
          'icon-overlap': 'always',
          'icon-size': 0.3,
          'icon-anchor': 'bottom'
        }
      });

      props.map.on('mouseenter', 'unclustered-point', () => {
        props.map.getCanvas().style.cursor = 'pointer';
      });

      props.map.on('mouseleave', 'unclustered-point', () => {
        props.map.getCanvas().style.cursor = '';
      });

      props.map.on('mouseenter', 'np', () => {
        props.map.getCanvas().style.cursor = 'pointer';
      });

      props.map.on('mouseleave', 'np', () => {
        props.map.getCanvas().style.cursor = '';
      });

      props.map.on('click', 'np', async (e) => {
        const features = props.map.queryRenderedFeatures(e.point, {
          layers: ['np']
        });
        const clusterId = features[0].properties.cluster_id;
        const zoom = await props.map.getSource('np').getClusterExpansionZoom(clusterId);
        props.map.easeTo({
          center: features[0].geometry.coordinates,
          zoom
        });
      });

      props.map.on('click', 'unclustered-point', (e) => {
        emit('select', {
          ref: e.features[0].properties?.id
        })
      });

      handleUpdate()

      mounted.value = true
    })

    watch(
      selected,
      (newVal, oldVal) => {
        if (newVal?.ref === oldVal?.ref || !mounted.value) return

        handleUpdate()
      }
    );

    watch(
      data,
      () => {
        handleUpdate()
      }
    );

    onUnmounted(() => {
      props.map.removeImage('np-icon');
      props.map.removeImage('np-icon-active');
      props.map.removeLayer('np');
      props.map.removeLayer('np-selected');
      props.map.removeLayer('unclustered-point');
      props.map.removeLayer('cluster-count');
      props.map.removeSource('np');
      props.map.removeSource('np-selected');

      props.map.off()

      if (popup.value) popup.value.remove()
    })
  }
});
</script>
