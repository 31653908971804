<template></template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  watch,
  onUnmounted,
  ref, getCurrentInstance
} from 'vue';

import { shippingMapClusterPickup } from '$modules/shipping/helpers/shipping-map-cluster';
import MaplibreGL from 'maplibre-gl/dist/maplibre-gl'

export default defineComponent({
  name: 'ShippingMapPickup',
  components: {
  },
  props: {
    map: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      default: () => ([])
    },
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      required: true
    },
    iconActive: {
      type: String,
      required: true
    },
    selected: {
      type: Object,
      default: null
    }
  },
  setup (props, { emit }) {
    const { proxy: vm } = getCurrentInstance()

    const popup = ref(null)
    const mounted = ref(false)
    const selected = computed(() => props.selected)
    const type = computed(() => props.type)
    const typeLabel = computed(() => vm.$t(type.value))

    const data = computed(() => {
      if (!type.value) return []

      return props.list.find(i => i.type === type.value)?.list || []
    })

    const handleUpdate = () => {
      const list = data.value.filter(i => i.id !== props.selected?.id);

      const geojsonData = shippingMapClusterPickup(list, type.value)
      const selectedData = shippingMapClusterPickup(props.selected ? [props.selected] : [], type.value)

      props.map.getSource('pickup').setData(geojsonData);
      props.map.getSource('pickup-selected').setData(selectedData);

      if (!props.selected) {
        popup.value?.remove();
        popup.value = null

        return
      }

      const html = `
      <div class="shp-area-map__popup-title">${typeLabel.value}</div>
      ${props.selected?.address}
      `

      popup.value = new MaplibreGL.Popup({
        offset: {
          bottom: [0, -60]
        }
      })
        .setLngLat([props.selected?.long, props.selected?.lat])
        .setHTML(html)
        .setMaxWidth('500px')
        .addTo(props.map);
    }

    onMounted(() => {
      props.map.loadImage(props.icon)
        .then((image) => props.map.addImage('pickup-icon', image.data))

      props.map.loadImage(props.iconActive)
        .then((image) => props.map.addImage('pickup-icon-active', image.data))

      props.map.addSource('pickup', {
        type: 'geojson',
        data: shippingMapClusterPickup([]),
        cluster: false,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      props.map.addSource('pickup-selected', {
        type: 'geojson',
        data: shippingMapClusterPickup([]),
        cluster: false,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      props.map.addLayer({
        'id': 'pickup',
        'type': 'symbol',
        'source': 'pickup',
        'layout': {
          'icon-image': 'pickup-icon',
          'icon-overlap': 'always',
          'icon-size': 0.3,
          'icon-anchor': 'bottom'
        }
      });

      props.map.addLayer({
        'id': 'pickup-selected',
        'type': 'symbol',
        'source': 'pickup-selected',
        'layout': {
          'icon-image': 'pickup-icon-active',
          'icon-overlap': 'always',
          'icon-size': 0.3,
          'icon-anchor': 'bottom'
        }
      });

      props.map.on('mouseenter', 'pickup', () => {
        props.map.getCanvas().style.cursor = 'pointer';
      });

      props.map.on('mouseleave', 'pickup', () => {
        props.map.getCanvas().style.cursor = '';
      });

      props.map.on('click', 'pickup', (e) => {
        emit('select', {
          shopId: e.features[0].properties?.id,
          type: e.features[0].properties?.type
        })
      });

      handleUpdate()

      mounted.value = true
    })

    watch(
      selected,
      (newVal, oldVal) => {
        if (!newVal || newVal?.id === oldVal?.id || !mounted.value) return

        handleUpdate()
      }
    );

    watch(
      data,
      () => {
        handleUpdate()
      }
    );

    watch(
      type,
      () => {
        if (popup.value) popup.value.remove()
        handleUpdate()
      }
    );

    onUnmounted(() => {
      props.map.removeImage('pickup-icon');
      props.map.removeImage('pickup-icon-active');
      props.map.removeLayer('pickup-selected');
      props.map.removeLayer('pickup');
      props.map.removeSource('pickup');
      props.map.removeSource('pickup-selected');

      props.map.off()

      if (popup.value) popup.value.remove()
    })
  }
});
</script>
