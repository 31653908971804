import { mapState } from 'vuex';
import { getProductWeight } from 'theme/helpers/product';
import { weightGToKg } from 'theme/filters';

export default {
  name: 'ShippingNovaMixin',
  props: {
    config: {
      type: Object,
      default: () => null
    },
    draft: {
      type: Object,
      default: () => null
    },
    addressList: {
      type: Array,
      default: () => []
    },
    loadingDelivery: {
      type: Boolean,
      required: true
    },
    loadingDeliveryInput: {
      type: Boolean,
      required: true
    },
    loadingNewPost: {
      type: Boolean,
      required: true
    },
    loadingNewPostInput: {
      type: Boolean,
      required: true
    },
    error: {
      type: Object,
      default: () => ({})
    },
    isNewPostOverweight: {
      type: Boolean,
      default: () => false
    },
    isCartHasAlcohol: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      getCityListForGeocode: state => state['shipping-module'].cityListForGeocode,
      getNpCityList: state => state['shipping-module'].npCityList,
      getNpShopList: state => state['shipping-module'].npShopList,
      productsInCart: state => state.cart.cartItems
    }),
    totalWeightSummary () {
      const weight = this.productsInCart.reduce((result, product) => {
        return result + (getProductWeight(product) * product.qty);
      }, 0);

      return weightGToKg(weight)
    },
    autocompletePlaceLabel () {
      if (this.draft.npType === 'department') return 'Shipping address derpartment'

      return 'Shipping address poshtomat'
    },
    validationError () {
      if (this.draft.npShop?.alcohol && this.isCartHasAlcohol) {
        return this.$t('New post alcohol restriction')
      }

      if (!this.isNewPostOverweight) return

      return this.$t(`Your New Post warehouse exceeds {overweigh} ${this.draft.npType}`, {
        overweigh: this.countItemInvalidWeight(this.draft.npShop)
      })
    }
  },
  data () {
    return {
      loading: {
        city: false
      }
    }
  },
  methods: {
    countItemInvalidWeight (item) {
      if (!item) return false

      return (this.totalWeightSummary - item.wgh).toFixed(2)
    },
    inputCity (text) {
      this.$emit('autocomplete-city', { text })
    },
    setCity (value) {
      this.$emit('set-city', value)
    },
    setShop (value) {
      this.$emit('set-shop', value)
    },
    setNewPostType (value) {
      this.$emit('set-type', value)
    },
    async inputText (text) {
      const { lng, lat } = this.draft.city.coordinates

      this.$emit('autocomplete', { lng, lat, text })
    },
    setAddress (data) {
      this.$emit('set-address', {
        ...data,
        from: 'list'
      })
    },
    clear () {
      this.$emit('set-address', null)
      this.$emit('set-type', null)
    }
  }
}
