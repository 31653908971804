<template>
  <span class="shp-nova-item">
    <button
      class="m-input-autocomplete__list-item shp-nova-item--button"
      data-transaction-name="Autocomplete - Set Input"
      :disabled="!!checkItemValidation"
      @click="setInputValue(item)"
    >
      {{ item[property] }}
    </button>
    <span
      v-if="checkItemValidation"
      class="shp-nova-item--error m-input-autocomplete__list-wrapper"
    >
      {{ checkItemValidation }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'ShippingNovaShopItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    property: {
      type: String,
      required: true
    },
    totalWeightSummary: {
      type: Number,
      required: true
    },
    isPoshtomat: {
      type: Boolean,
      required: true
    },
    isCartHasAlcohol: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    checkItemValidation () {
      if (this.item?.alcohol && this.isCartHasAlcohol) {
        return this.$t('New post alcohol restriction')
      }

      if (this.totalWeightSummary > this.item.max_weight) {
        const type = this.isPoshtomat ? 'poshtomat' : 'department'
        return this.$t(`Your New Post warehouse exceeds {overweigh} ${type}`, { overweigh: this.countItemInvalidWeight(this.item) })
      }

      return false
    }
  },
  methods: {
    countItemInvalidWeight (item) {
      if (!item) return false

      return (this.totalWeightSummary - item.max_weight).toFixed(2)
    },
    setInputValue (item) {
      return this.$emit('set', item)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.shp-nova-item {
  &--button {
    color: var(--black);
    background: none;

    &:disabled {
      color: var(--gray);
      padding-bottom: var(--spacer-4);
    }
  }

  &--error {
    box-sizing: border-box;
    padding-top: 0;
    font-size: var(--font-size-10);
    color: var(--c-danger)
  }
}
</style>
