import { LngLat } from 'maplibre-gl/dist/maplibre-gl'
import debounce from 'lodash/debounce';
import { deliveryMethods, pickupMethods } from '$modules/shipping/config';

const DEFAULT_ZOOM = 11
const DEFAULT_MAP_ZOOM = 5
const DEFAULT_ADDRESS_ZOOM = 16
const DEFAULT_SHOP_ZOOM = 18
const DEFAULT_CENTER = { lat: 50.450001, lng: 30.523333 }

const PRIORITY = {
  RESET: 0,
  CITY: 1,
  CITY_NP: 1.5,
  POINT: 2,
  ADDRESS: 3
}

export default {
  name: 'ShippingMapMixin',
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    draft: {
      type: Object,
      default: () => null
    },
    npCenter: {
      type: Array,
      required: true
    },
    pickupGroups: {
      type: Array,
      required: true
    },
    npShopList: {
      type: Array,
      required: true
    },
    loadingDelivery: {
      type: Boolean,
      required: true
    },
    loadingNewPost: {
      type: Boolean,
      required: true
    },
    loadingShippingModule: {
      type: Boolean,
      required: true
    },
    loadingUserLocate: {
      type: Boolean,
      required: true
    },
    locateError: {
      type: Boolean,
      required: true
    },
    initialized: {
      type: Boolean,
      required: true
    }
  },
  data () {
    const {
      coordinates,
      zoom
    } = this.getCoordinates(this.draft)

    return {
      map: null,
      mapReady: false,
      mapZoom: zoom || DEFAULT_MAP_ZOOM,
      mapCenter: coordinates,
      zoom: zoom || DEFAULT_MAP_ZOOM,
      center: coordinates,
      mapOptions: {
        preferCanvas: true,
        scrollWheelZoom: 'center',
        doubleClickZoom: 'center',
        touchZoom: 'center',
        minZoom: 2,
        maxZoom: 18,
        boxZoom: false,
        zoomControl: false,
        attributionControl: false,
        zoomSnap: 0.5
      },
      showPointer: false,
      vector: {
        npIcon: '/assets/map/pointer-new-np.webp',
        npActiveIcon: '/assets/map/pointer-new-np-active.webp',
        shopIcon: '/assets/map/pointer-shop.webp',
        shopActiveIcon: '/assets/map/pointer-shop-active.webp'
      },
      methodZoom: {},
      skipNextRequest: false,
      activeDelivery: false,
      keepCenter: null,
      buffer: null
    }
  },
  watch: {
    'draft.method': {
      handler: function (val, oldVal) {
        if (val === 'NOVA') {
          this.resetToMap()

          return
        }

        if (val === oldVal || !this.draft?.city?.coordinates) return

        this.skipNextRequest = true
        this.activeDelivery = false

        const zoom = val === this.config.codes.delivery
          ? DEFAULT_ADDRESS_ZOOM
          : DEFAULT_ZOOM

        this.setCenterAndZoom(
          this.draft?.city.coordinates.lat,
          this.draft?.city.coordinates.lng,
          this.methodZoom[this.draft?.method] || zoom,
          PRIORITY.CITY
        )
      }
    },
    'draft.city': {
      immediate: true,
      handler: function (val, oldVal) {
        if (!val?.id || val?.id === oldVal?.id) return

        this.activeDelivery = false
        this.skipNextRequest = true

        const zoom = this.draft?.method === this.config.codes.delivery
          ? DEFAULT_ADDRESS_ZOOM
          : DEFAULT_ZOOM

        this.setCenterAndZoom(
          val.coordinates.lat,
          val.coordinates.lng,
          zoom,
          PRIORITY.CITY
        )
      }
    },
    'draft.type': {
      handler: function (val, oldVal) {
        if (!val || val === oldVal || !this.draft?.city?.coordinates) return
        if (this.draft?.method === this.config.codes.delivery) return

        this.setCenterAndZoom(
          this.draft?.city.coordinates.lat,
          this.draft?.city.coordinates.lng,
          DEFAULT_ZOOM,
          PRIORITY.CITY
        )
      }
    },
    'draft.address': {
      handler: function (val, oldVal) {
        this.activeDelivery = !!val

        if (val?.label === oldVal?.label || !val?.coordinates) return

        this.skipNextRequest = true

        this.setCenterAndZoom(
          val.coordinates[1],
          val.coordinates[0],
          null,
          PRIORITY.ADDRESS
        )
      },
      immediate: true
    },
    'draft.shop': {
      immediate: true,
      handler: function (val, oldVal) {
        if (!val?.id || val?.id === oldVal?.id) return
        if (this.draft?.method === this.config.codes.delivery) return

        this.setCenterAndZoom(
          val.lat,
          val.long,
          DEFAULT_SHOP_ZOOM,
          PRIORITY.POINT
        )

        this.$nextTick(() => {
          this.openShopPopUp('native')
        })
      }
    },
    ready (val, oldVal) {
      if (!val || !this.buffer || val === oldVal) return

      if (this.center?.lng && this.center?.lat) {
        this.setCenterAndZoom(this.center?.lat, this.center?.lng, this.buffer.zoom, PRIORITY.POINT)
      } else {
        this.setCenterAndZoom(this.buffer.lat, this.buffer.lng, this.buffer.zoom, PRIORITY.RESET)
      }

      this.buffer = null
    },
    npCenter: {
      handler: function (val) {
        if (!val?.length && !this.draft?.npShop) return

        const [lng, lat] = val

        const zoom = this.draft?.npType === 'address' ? DEFAULT_ADDRESS_ZOOM : DEFAULT_ZOOM

        this.setCenterAndZoom(lat, lng, zoom, PRIORITY.CITY_NP)
      },
      immediate: true
    },
    'draft.npShop': {
      handler: function (val) {
        if (!val) return

        this.setCenterAndZoom(
          val.lat,
          val.long,
          DEFAULT_SHOP_ZOOM,
          PRIORITY.POINT
        )

        this.$nextTick(() => {
          this.openShopPopUp('nova')
        })
      }
    },
    'draft.npType': {
      handler: function (val, oldVal) {
        if (this.draft?.method !== 'NOVA' || val === oldVal) return

        this.resetToMap()
      }
    }
  },
  beforeDestroy () {
    this.mapEventsStop()
  },
  computed: {
    ready () {
      return this.initialized && this.mapReady
    },
    isNova () {
      return this.draft?.method === this.config.codes.newPost
    },
    isNovaCityReady () {
      return this.isNova && !!this.draft.npCity
    },
    isNovaDelivery () {
      return this.isNovaCityReady && this.draft?.npType === this.config.newPostTypes.address
    },
    npShopSelected () {
      if (!this.draft?.npShop?.ref) return null

      return (this.npShopList || []).find(i => i.ref === this.draft?.npShop?.ref) || null
    },
    shopSelected () {
      if (!this.draft.shop?.id) return null

      return this.draft.shop
    },
    mappedNpShopList () {
      return (this.npShopList || []).map(i => ({
        ref: i.ref,
        long: i.long,
        lat: i.lat
      }))
    }
  },
  methods: {
    resetToMap () {
      this.setCenterAndZoom(
        DEFAULT_CENTER.lat,
        DEFAULT_CENTER.lng,
        DEFAULT_MAP_ZOOM,
        PRIORITY.RESET
      )
    },
    zoomIn () {
      return this.map?.zoomIn()
    },
    zoomOut () {
      return this.map?.zoomOut()
    },
    setDeliveryCoordinatesHandler: debounce(function () {
      if (this.skipNextRequest) {
        this.skipNextRequest = false
        return
      }

      this.activeDelivery = false

      this.$emit('delivery-coordinates', this.center)
    }, 800),
    setCenter (center) {
      if (this.draft?.method !== this.config.codes.delivery && !this.isNovaDelivery) return

      this.center = center

      this.setDeliveryCoordinatesHandler()
    },
    setZoom (zoom) {
      this.zoom = zoom

      this.methodZoom[this.draft?.method] = zoom
    },
    setCenterAndZoom (lat, lng, zoom, priority) {
      if (!this.map?.easeTo || !this.ready) {
        this.buffer = this.buffer?.priority > priority
          ? this.buffer
          : {
            lat: lat,
            lng: lng,
            zoom: zoom || this.buffer?.zoom || null,
            priority: priority || 0
          }

        // this.mapCenter = new LngLat(this.buffer.lng, this.buffer.lat)
        // this.mapZoom = this.buffer.zoom

        return
      }

      const currentZoom = zoom || this.zoom
      const currentCenter = new LngLat(+lng, +lat) || this.center

      this.zoom = currentZoom

      if (!this.ready) {
        this.buffer = this.buffer?.priority > priority
          ? this.buffer
          : {
            lat: lat,
            lng: lng,
            zoom: currentZoom || this.buffer?.zoom || null,
            priority: priority || 0
          }

        // this.mapCenter = [this.buffer.lat, this.buffer.lng]
        // this.mapZoom = this.buffer.zoom

        return
      }

      this.setView(currentCenter, currentZoom)
    },
    setView: debounce(function (currentCenter, currentZoom) {
      this.map.easeTo({ center: currentCenter, zoom: currentZoom })
      this.showPointer = true
    }, 300),
    onMapReady () {
      this.map = this.$refs.map.mapObject

      this.mapEventsInitialize()

      this.mapReady = true
    },
    popupOpen (options) {
      if (!options) return

      const event = this.isNova
        ? 'set-np-shop'
        : 'set-shop'

      this.$emit(event, options)
    },
    clearDeliveryError () {
      this.$emit('clear-locate-error')
    },
    move () {
      this.$emit('move')
    },
    moveStart () {
      if (this.draft?.method !== this.config.codes.delivery) return

      this.skipNextRequest = false
      this.activeDelivery = false

      this.move()
    },
    zoomStart () {
      this.keepCenter = this.center
    },
    zoomEnd () {
      this.center = this.keepCenter
      this.keepCenter = null
      this.skipNextRequest = true
    },
    mapEventsInitialize () {
      if (!this.map) return

      this.map.on('move', this.move);
      this.map.on('zoomstart', this.zoomStart);
      this.map.on('zoomend', this.zoomEnd);
      this.map.on('dragstart', this.moveStart);
    },
    mapEventsStop () {
      if (!this.map) return

      this.map.off('move', this.move);
      this.map.off('zoomstart', this.zoomStart);
      this.map.off('zoomend', this.zoomEnd);
      this.map.off('dragstart', this.moveStart);
    },
    openShopPopUp (type) {
      const popup = this.$refs[`shop-${type}-current`]?.mapObject

      if (!popup) return

      setTimeout(() => popup.openPopup(), 0)
    },
    getCoordinates (buffer) {
      const isDelivery = deliveryMethods.includes(buffer?.method?.toUpperCase())
      const isPickup = pickupMethods.includes(buffer?.method?.toUpperCase())

      if (isDelivery && buffer?.address) {
        return {
          coordinates: new LngLat(buffer.address?.coordinates?.[0], buffer.address?.coordinates?.[1]),
          zoom: DEFAULT_ADDRESS_ZOOM
        }
      }

      if (buffer?.npShop && buffer?.npShop.lat) {
        return {
          coordinates: new LngLat(+buffer?.npShop.long, +buffer?.npShop.lat),
          zoom: DEFAULT_SHOP_ZOOM
        }
      }

      if (isPickup && buffer?.shop) {
        return {
          coordinates: new LngLat(+buffer?.shop?.long, +buffer?.shop?.lat),
          zoom: DEFAULT_SHOP_ZOOM
        }
      }

      if (buffer?.npCity?.ref && this.npCenter) {
        const [lng, lat] = this.npCenter

        return {
          coordinates: new LngLat(lng, lat),
          zoom: DEFAULT_SHOP_ZOOM
        }
      }

      if (buffer?.city?.coordinates?.lng) {
        return {
          coordinates: new LngLat(+buffer?.city.coordinates.lng, +buffer?.city.coordinates.lat),
          zoom: DEFAULT_SHOP_ZOOM
        }
      }

      return {
        coordinates: new LngLat(DEFAULT_CENTER.lng, DEFAULT_CENTER.lat),
        zoom: DEFAULT_ZOOM
      }
    }
  }
}
